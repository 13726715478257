<template>
  <div class="ev2-box log-leilao hscroll">
    <div class="bg-helper1"></div>
    <div class="header">
      <div class="hcol1">
        <div class="title">Log Operacional <small class="font-thin">({{pagination.total}})</small></div>
        <div class="subtitle">Monitoramento de ações no leilão</div>
      </div>
      <div class="hcol2">
        <a @click="baixar" class="m-r-lg text-white font-12"><i class="fa fa-duotone fa-file-pdf" /> Baixar auditoria</a>
        <i class="fal fa-refresh cursor-pointer" @click="() => load()" v-if="!loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body m-t-md scroll" style="flex: 1; max-height: 400px; min-height: 400px; overflow: auto">
      <table class="table-logs">
        <thead>
        <tr>
          <th>Data</th>
          <th>Evento</th>
          <th>Pessoa</th>
<!--          <th>Ações</th>--> <!-- @TODO: Implementar ver log -->
        </tr>
        </thead>
        <tbody>
        <tr v-for="log in logs" :key="log._id">
          <td>{{ log.date|formatDatetimeStr }}</td>
          <td>{{ log.description }}</td>
          <td v-if="log.userName">{{ firstLastname(log.userName) }}</td>
          <td v-else>-</td>
<!--          <td></td>-->
        </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="pagination.pages > 1">
        Páginas: <a @click="load(page)" v-for="page in pagination.pages" :class="{active: page === pagination.currentPage}">{{page}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import {leilaoLog} from "@/domain/stats/service"
import SlLoading from "components/layout/components/Loading.vue"
import {donwloadFile} from "@/utils/downloadFile";

export default {
  name: "LogLeilao",
  data () {
    return {
      loading: true,
      logs: [],
      pagination: {
        pages: 1,
        correntPage: 1,
        limit: 200,
        total: 0
      }
    }
  },
  components: {
    SlLoading
  },
  mounted() {
    this.load()
  },
  mixins: [tabMixin],
  computed: {
  },
  methods: {
    load (page) {
      this.loading = true
      leilaoLog(this.leilao.id, page, this.pagination.limit)
          .then(response => {
            this.loading = false
            if (response.data && Array.isArray(response.data.result)) {
              this.logs = response.data.result
              const {result, ...rest} = response.data
              this.pagination = rest
            }
          })
          .catch(error => {
            console.error(error)
            this.loading = false
            this.logs = []
          })
    },
    baixar () {
      leilaoLog(this.leilao.id, null, null, true)
          .then((response) => {
            console.log(response.data)
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
</style>
